<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold text-center ">
          Upload Build
        </h4>
      </CCardHeader>
    </template>
    <CCardBody class="">

      <div v-if="!file_path">
        <div class="d-flex flex-column align-items-center py-4" v-if="!uploading">
          <button class="btn btn-primary rounded-pill text-center text-uppercase px-5 mb-3"
                  @click="$refs.file_upload.click()">Upload Build File
          </button>
          <div class="invalid-feedback d-block mt-3" v-if="hasError('audio')">
            <span class="d-block">{{ getError('audio') }}</span>
          </div>
          <input class="form-control d-none" accept="application/vnd.android.package-archive,application/octet-stream"
                 type="file" @change="select" ref="file_upload">
        </div>
        <div class="row py-5" v-if="uploading">
          <div class="col-md-6 mx-auto">
            <CProgressBar></CProgressBar>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="{width: progress+'%'}"></div>
            </div>
            <div class="d-flex justify-content-between">
              <span>0%</span>
              <span>100%</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <CInput label="Version"
                v-model="version"
                :addInputClasses="{'is-invalid': hasError('version')}"
                :invalid-feedback="getError('version')"
        ></CInput>
        <div class="d-flex mt-3 align-items-center justify-content-center">
          <CButton color="secondary" class="mx-auto text-uppercase"
                   @click="showModal = false"
          >
            Cancel
          </CButton>
          <CButton color="danger" class="mx-auto text-uppercase btn_yes"
                   @click="save()"
          >
            Save
          </CButton>
        </div>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation"

export default {
  name: "UploadBuildModal",
  mixins: [hasApiValidation],
  data() {
    return {
      showModal: false,
      version: null,
      file: null,
      chunks: [],
      uploaded: 0,
      uploading: false,
      is_first: true,
      file_path: null,
      loading: false,
    }
  },
  watch: {
    chunks(n) {
      if (n.length > 0) {
        this.upload();
        this.is_first = false;
      }
    },
    uploading(n) {
      if (n) {
        this.$noty.success('Upload Start')
      }
    }
  },
  methods: {
    open() {
      this.showModal = true;
      this.file = null;
      this.chunks = [];
      this.uploaded = 0;
      this.uploading = false;
      this.is_first = true;
      this.file_path = null;
      this.loading = false;
    },
    save() {
      this.setErrors({});
      this.$http.post('/enterprise-builds/', {version: this.version, path: this.file_path})
          .then(({data}) => {
            this.showModal = false;
            this.$emit('saved');
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
          })
    },
    closeModal() {
      this.$emit('modal:close');
    },

    select(event) {
      this.file = event.target.files.item(0);
      if (this.file) {
        let f_size = this.file.size / 1024 / 1024;
        let f_ext = this.file.name.split('.').pop();
        let f_type = this.file.type;
        // if (f_size > 150) {
        //   this.$noty.error('File must not be greater than 150 MBytes');
        //   return;
        // }
        if (f_ext !== 'apk') {
          this.$noty.error('File not valid apk build');
          return;
        }
        this.createChunks();
      }
    },
    upload() {
      this.uploading = true;
      this.$http(this.config).then(({data}) => {
        this.chunks.shift();
        if (this.chunks.length === 0) {
          this.file = null;
          this.uploading = false;
          this.uploaded = 0;
          this.$noty.success('Uploaded')
          this.file_path = data.data.path;
          this.version = data.data.version;
        }
      }).catch((err) => {
        let response = err.response
        if (response && response.data && response.data.message) {
          this.$noty.error(response.data.message);
        }
        this.file = null;
        this.chunks = null;
        this.uploading = false;
      });
    },
    createChunks() {
      let limit_mb = this.$route.query.size ?? 2;
      let size = 1024 * 1024 * limit_mb, chunks = Math.ceil(this.file.size / size);
      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
            i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
    }
  },
  computed: {
    progress() {
      return this.file ? this.uploaded / (this.uploaded + this.chunks.length) * 100 : null;
    },
    formData() {
      let formData = new FormData;
      formData.set('is_first', this.is_first);
      formData.set('is_last', this.chunks.length === 1);
      formData.set('f_parts', this.chunks[0], `${this.file.name}.part`);
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: '/enterprise-builds/',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/json'
        },
        onUploadProgress: () => {
          this.uploaded += 1;
        }
      };
    }
  }
}
</script>

<style scoped>

</style>